<template>
  <div class="text-lg text-grey-darkest leading-normal spaced-y-6">
    <p>
      I'm Noman Sheikh, Student, a part-time freelance full-stack developer.
    </p>
  </div>
</template>

<script>

export default {
  name: 'Home',
};
</script>
