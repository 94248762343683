<template>
  <div class="py-8 lg:py-16 px-6 md:px-16 lg:px-24">
    <div class="relative z-20 flex justify-between items-center">
      <div class="flex md:block lg:flex items-center max-w-full">
        <div class="mb-0 md:mb-4 lg:mb-0 flex flex-no-shrink pr-4 md:pr-6 lg:pr-12">
          <a href="/" class="flex items-center no-underline">
            <img src="https://avatars.githubusercontent.com/u/40509801" alt="Noman Sheikh" class="h-10 w-10 md:h-12 md:w-12 lg:h-20 lg:w-20 rounded-full">
          </a>
        </div>
        <div>
          <a href="/" class="block text-black no-underline font-bold text-xl lg:text-3xl font-extrabold leading-none lg:leading-tight">Noman Sheikh</a>
          <div class="md:flex mt-3 lg:mt-4 uppercase tracking-wide text-xs space-x-6">
            <router-link :to="{ name: 'home' }" class="text-gray-600 hover:text-gray-800 font-semibold no-underline hover:text-black">About me</router-link>
            <router-link :to="{ name: 'projects' }" class="text-gray-600 hover:text-gray-800 font-semibold no-underline hover:text-black">Projects</router-link>
          </div>
        </div>
      </div>
    </div>

    <div class="lg:pl-32 mt-12">
      <div class="max-w-2xl">
        <router-view/>
      </div>
    </div>
  </div>
</template>

<style lang="scss">

</style>
